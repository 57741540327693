import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class ConcessionsRepository extends BaseRepository {
    baseUrl = 'management/specifications/concessions';

    /**
     * Returns all highways for a given concession
     *
     * @param concessionId
     * @param criteria
     * @return {*}
     */
    highways(concessionId, criteria) {
        return this.httpClient.post(`${this.baseUrl}/${concessionId}/highways`, criteria);
    }

    /**
     * Returns all external highways for a given concession
     *
     * @param concessionId
     * @param criteria
     * @return {*}
     */
    externalHighways(concessionId, criteria) {
        return this.httpClient.post(`${this.baseUrl}/${concessionId}/external-highways`, criteria);
    }

    /**
     * Returns all concessions for the logged user entity team lots
     *
     * @return {*}
     */
    allByLoggedUser() {
        return this.httpClient.get(`${this.baseUrl}/all-by-logged-user`);
    }
}
